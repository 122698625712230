import { Role } from 'graphql/types';

export enum Roles {
  admin = 'ADMIN',
  user = 'USER',
}

export const RoleValues = {
  [Role.SchemeAdmin]: 'Scheme Admin',
  [Role.ContentAdmin]: 'Content Admin',
  [Role.User]: 'User',
  [Role.ShopsafeAdmin]: 'Shopsafe Admin',
  [Role.GroupAdmin]: 'Group Admin',
} as const;
