import antdDaDK from 'antd/es/locale/da_DK';
import antdDeDE from 'antd/es/locale/de_DE';
import antdItIT from 'antd/es/locale/it_IT';
import antdNlNL from 'antd/es/locale/nl_NL';
import antdFiFI from 'antd/es/locale/fi_FI';
import antdPlPL from 'antd/es/locale/pl_PL';
import antdPtPT from 'antd/es/locale/pt_PT';
import antdSvSE from 'antd/es/locale/sv_SE';
import antdFrFR from 'antd/es/locale/fr_FR';
import antdEnUS from 'antd/es/locale/en_US';
import antdEsES from 'antd/es/locale/es_ES';
import daMsg from '../locales/da_DK.json';
import deMsg from '../locales/de_DE.json';
import itMsg from '../locales/it_IT.json';
import nlMsg from '../locales/nl_NL.json';
import fiMsg from '../locales/fi_FI.json';
import plMsg from '../locales/pl_PL.json';
import ptMsg from '../locales/pt_PT.json';
import svMsg from '../locales/sv_SE.json';
import frMsg from '../locales/fr_FR.json';
import enMsg from '../locales/en_US.json';
import esMsg from '../locales/es_ES.json';

const EnLang = {
  antd: antdEnUS,
  locale: 'en-US',
  messages: {
    ...enMsg,
  },
};
const FrLang = {
  antd: antdFrFR,
  locale: 'fr-FR',
  messages: {
    ...frMsg,
  },
};

const DeLang = {
  antd: antdDeDE,
  locale: 'de-DE',
  messages: {
    ...deMsg,
  },
};

const EsLang = {
  antd: antdEsES,
  locale: 'es-ES',
  messages: {
    ...esMsg,
  },
};

const DaLang = {
  antd: antdDaDK,
  locale: 'da-DK',
  messages: {
    ...daMsg,
  },
};

const ItLang = {
  antd: antdItIT,
  locale: 'it-IT',
  messages: {
    ...itMsg,
  },
};

const NlLang = {
  antd: antdNlNL,
  locale: 'nl-NL',
  messages: {
    ...nlMsg,
  },
};

const FiLang = {
  antd: antdFiFI,
  locale: 'fi-FI',
  messages: {
    ...fiMsg,
  },
};

const PlLang = {
  antd: antdPlPL,
  locale: 'pl-PL',
  messages: {
    ...plMsg,
  },
};

const PtLang = {
  antd: antdPtPT,
  locale: 'pt-PT',
  messages: {
    ...ptMsg,
  },
};

const SvLang = {
  antd: antdSvSE,
  locale: 'sv-SE',
  messages: {
    ...svMsg,
  },
};

export {
  DeLang,
  DaLang,
  ItLang,
  NlLang,
  FiLang,
  PlLang,
  PtLang,
  SvLang,
  EnLang,
  FrLang,
  EsLang,
};
