/* eslint-disable react/jsx-filename-extension */
import React from "react";

/* eslint-disable-next-line */
export const GoogleSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path
        fill="#59C36A"
        d="M827.301,875.968c-84.521,71.388-194.169,116.693-313.528,116.693c-177.609,0-333.52-97.848-418.041-240.62
				l31.646-145.858l140.255-26.039c32.553,105.078,130.779,182.178,246.141,182.178c55.964,0,107.937-17.703,150.767-49.112
				l134.777,20.558L827.301,875.968z"
      />
      <path
        fill="#00A66C"
        d="M827.301,875.968l-27.984-142.201l-134.777-20.558c-42.83,31.409-94.803,49.112-150.767,49.112v230.34
				C633.132,992.661,742.779,947.355,827.301,875.968z"
      />
      <g id="Connected_Home_1_">
        <g>
          <g>
            <g>
              <path
                fill="#FFDA2D"
                d="M256.781,505.331c0,26.267,3.998,51.396,10.851,74.813l-171.9,171.897
								c-42.83-71.957-69.29-156.48-69.29-246.71c0-90.233,26.46-174.754,69.29-246.711l137.965,23.743l33.936,148.154
								C260.779,453.932,256.781,479.06,256.781,505.331L256.781,505.331z"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        fill="#4086F4"
        d="M1001.103,505.331c0,148.48-68.719,281.547-173.802,370.637L664.539,713.209
				c33.121-23.988,61.107-55.971,79.384-93.66h-230.15c-15.993,0-28.556-12.567-28.556-28.554V419.666
				c0-15.99,12.563-28.554,28.556-28.554h450.78c13.707,0,25.698,9.708,27.983,23.412
				C998.247,444.225,1001.103,475.063,1001.103,505.331L1001.103,505.331z"
      />
      <path
        fill="#4175DF"
        d="M743.924,619.549c-18.275,37.689-46.264,69.672-79.382,93.66l162.759,162.759
				c105.083-89.09,173.802-222.153,173.802-370.637c0-30.269-2.855-61.106-8.567-90.807c-2.284-13.704-14.278-23.412-27.984-23.412
				H513.772v228.437H743.924z"
      />
      <path
        fill="#FF641A"
        d="M835.297,154.107c0.571,7.996-2.855,15.422-7.996,21.131L705.086,296.881
				c-9.704,10.278-25.694,11.421-37.118,2.855c-45.119-33.693-98.231-51.396-154.195-51.396
				c-115.361,0-213.588,77.095-246.141,182.178L95.731,258.62C180.253,115.848,336.163,18,513.772,18
				c113.647,0,224.439,41.88,311.244,114.978C831.298,138.119,834.723,146.112,835.297,154.107L835.297,154.107z"
      />
      <path
        fill="#F03800"
        d="M667.966,299.736c11.422,8.567,27.411,7.423,37.119-2.855l122.214-121.643
				c5.143-5.709,8.569-13.133,7.996-21.131c-0.575-7.997-3.999-15.988-10.279-21.13C738.212,59.88,627.42,18,513.772,18v230.34
				C569.736,248.34,622.849,266.043,667.966,299.736z"
      />
    </g>
  </svg>
);

/* eslint-disable-next-line */
export const FacebookSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path
        fill="#4A7AFF"
        d="M1015.363,506.525c0,279.749-226.775,506.524-506.525,506.524c-279.749,0-506.524-226.775-506.524-506.524
	C2.313,226.775,229.089,0,508.838,0C788.588,0,1015.363,226.775,1015.363,506.525z"
      />
      <path
        fill="#FFFFFF"
        d="M663.688,149.015v114.697c0,0-131.686-19.113-131.686,65.843v84.957h118.941L636.08,544.072H532.002v337.709
	H400.316V544.072l-112.572-2.126V414.512H398.19V316.81c0,0-7.288-145.343,135.938-172.038
	C593.602,133.68,663.688,149.015,663.688,149.015z"
      />
      <path
        fill="#DCE1EB"
        d="M663.688,263.712V149.015c0,0-70.086-15.335-129.56-4.243c-9.291,1.73-17.932,3.973-25.993,6.623v730.387
	h23.867V544.072h104.07l14.871-129.561H532.002c0,0,0,0,0-84.957C532.002,244.599,663.688,263.712,663.688,263.712z"
      />
    </g>
  </svg>
);

export const ImageSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <g>
        <path
          fill="#E5E1E5"
          d="M163.033,1011.756L2.746,191.353c-4.863-24.879,11.365-48.991,36.245-53.852l124.042-24.234l194.642-42.82
          l279.663,941.308H163.033V1011.756z"
        />
      </g>
      <path
        fill="#99E6FC"
        d="M163.033,680.979L68.355,196.393l94.678-18.501l143.802-23.615l62.994,584.599L163.033,680.979z"
      />
      <g id="XMLID_159_">
        <g>
          <path
            fill="#F9F7F8"
            d="M1014.357,64.9v957.461c0,25.351-20.549,45.899-45.899,45.899H208.93
            c-25.351,0-45.901-20.549-45.901-45.899V64.9c0-25.35,20.551-45.9,45.901-45.9h759.528C993.809,19,1014.357,39.551,1014.357,64.9
            z"
          />
        </g>
        <path
          fill="#EFEDEF"
          d="M574.473,971.206c-90.848,0-164.495-73.646-164.495-164.493V19H208.93
          c-25.351,0-45.901,20.551-45.901,45.9v957.461c0,25.351,20.551,45.899,45.901,45.899h759.528
          c25.351,0,45.899-20.549,45.899-45.899v-51.155H574.473z"
        />
        <path
          fill="#FEC165"
          d="M950.933,737.554V234.861c0-7.122-5.774-12.896-12.897-12.896H239.354c-7.12,0-12.896,5.774-12.896,12.896
          v502.692H950.933z"
        />
        <path
          fill="#FDB441"
          d="M409.978,221.965H239.354c-7.12,0-12.896,5.774-12.896,12.896v502.692h183.52V221.965z"
        />
        <circle fill="#FEE903" cx="588.693" cy="600.309" r="246.948" />
        <path
          fill="#F4D902"
          d="M409.978,770.729V429.889c-42.274,44.316-68.229,104.339-68.229,170.419
          C341.748,666.391,367.703,726.41,409.978,770.729z"
        />
        <g>
          <path
            fill="#99E6FC"
            d="M902.813,668.316c-57.591-25.393-122.604-28.267-182.203-8.034l-51.163,17.336
            c-52.369,17.759-109.135,17.759-161.505,0l-51.163-17.336c-59.602-20.232-124.611-17.358-182.182,8.034l-48.142,21.226v105.269
            l80.12,33.354h599.658l44.699-33.354V689.542L902.813,668.316z"
          />
          <path
            fill="#62DBFB"
            d="M409.978,828.165V649.264c-45.72-6.239-92.605,0.184-135.379,19.053l-48.141,21.226v105.269
            l80.119,33.354H409.978z"
          />
          <path
            fill="#62DBFB"
            d="M950.933,794.811v61.709c0,5.452-4.424,9.878-9.879,9.878H236.332c-5.453,0-9.877-4.426-9.877-9.878
            v-61.709l48.142-21.229c57.57-25.39,122.58-28.268,182.182-8.055l51.163,17.358c52.37,17.759,109.136,17.759,161.505,0
            l51.163-17.358c59.6-20.213,124.612-17.335,182.203,8.055L950.933,794.811z"
          />
          <path
            fill="#01D0FB"
            d="M236.332,866.397h184.86c-7.214-18.51-11.215-38.625-11.215-59.685v-52.188
            c-45.72-6.231-92.605,0.192-135.379,19.061l-48.141,21.226v61.71c-0.003,5.451,4.421,9.875,9.874,9.875V866.397z"
          />
        </g>
      </g>
    </g>
  </svg>
);
